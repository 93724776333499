<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div v-if="marcoLegalEspecificoId">
          <router-link
            v-if="prevMLE"
            :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${prevMLE}/editar`"
            class="m-2 c-text-primary"
          >
            <i class="fa-solid fa-chevron-left"></i>
          </router-link>
          <router-link
            v-if="nextMLE"
            :to="`/admin/marcos-legales-generales/${marcoLegalGeneralId}/versiones/${marcoLegalGeneralVersionId}/marcos-legales-especificos/${nextMLE}/editar`"
            class="m-2 c-text-primary"
          >
            <i class="fa-solid fa-chevron-right"></i>
          </router-link>
        </div>
        <c-card :title="`Editar Marco Legal Específico`">
          <marco-legal-especifico-form @setNextAndPrev="getNextAndPrevMLE" />
        </c-card>
      </div>
    </div>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import MarcoLegalEspecificoForm from "@/components/MarcoLegalGeneral/MarcoLegalEspecificoForm";
import { marcosLegalesEspecificos } from "@/services/marcos_legales_generales_versions";
export default {
  components: {
    MarcoLegalEspecificoForm,
    CCard,
  },
  data() {
    return {
      marcoLegalGeneralId: this.$route.params.id,
      marcoLegalGeneralVersionId: this.$route.params.versionId,
      marcoLegalEspecificoId: this.$route.params.mleId,
      marcosLegalesEspecificos: [],
      nextMLE: null,
      prevMLE: null,
    };
  },
  mounted() {
    this.getMarcosLegalesEspecificos();
  },
  methods: {
    getMarcosLegalesEspecificos() {
      marcosLegalesEspecificos(this.marcoLegalGeneralVersionId).then(
        (response) => {
          this.marcosLegalesEspecificos = response.data;
          this.getNextAndPrevMLE();
        }
      );
    },
    getNextAndPrevMLE() {
      this.marcoLegalEspecificoId = this.$route.params.mleId;
      if (!this.marcoLegalEspecificoId) {
        return;
      }
      const indexCurrentMLE = this.marcosLegalesEspecificos.findIndex(
        (mle) => mle.id === this.marcoLegalEspecificoId
      );
      this.nextMLE =
        indexCurrentMLE < this.marcosLegalesEspecificos.length - 1
          ? this.marcosLegalesEspecificos[indexCurrentMLE + 1].id
          : null;
      this.prevMLE =
        indexCurrentMLE > 0
          ? this.marcosLegalesEspecificos[indexCurrentMLE - 1].id
          : null;
    },
  },
};
</script>
